import { useState, useEffect } from "react";
import { Smartlook, RenderingMode } from "@awesome-cordova-plugins/smartlook";
import { SettingsGeneral } from "../models/Settings";
import { isCapacitor, smartlookApiKey } from "../config";
import useStorage from "./useStorage";
import { Capacitor } from "@capacitor/core";
import { isPlatform } from "@ionic/core";

const useSmartlook = (settings?: SettingsGeneral) => {
  const [aleaIactaEst, setAleaIactaEst] = useState(false);
  const { auth, currentUser: user } = useStorage();

  useEffect(() => {
    if (!settings) return;
    if (!Capacitor.isNativePlatform() || !isPlatform("android")) return;
    if (!isCapacitor) return;

    if (!auth.accessToken && !aleaIactaEst && settings.r >= Math.random()) {
      setAleaIactaEst(true);
      Smartlook.isRecording().then((isRecording: boolean) => {
        if (!isRecording) {
          Smartlook.setProjectKey({ key: smartlookApiKey });
          Smartlook.start();
        }
      });
    }

    if (user) {
      Smartlook.isRecording().then((isRecording: boolean) => {
        if (user.settingsGeneral.r) {
          if (!isRecording) {
            Smartlook.setProjectKey({ key: smartlookApiKey });
            Smartlook.start();
          }

          Smartlook.setUserIdentifier({ identifier: user.userId.toString() });
        } else {
          if (isRecording) Smartlook.stop();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings?.r, user?.userId, auth.accessToken]);
};

const useSmartlookSensitive = (sensitive: boolean) => {
  useEffect(() => {
    if (isCapacitor)
      Smartlook.isRecording().then((isRecording) => {
        if (!isRecording) return;

        if (sensitive)
          Smartlook.setRenderingMode({
            renderingMode: RenderingMode.NO_RENDERING,
          });
        else
          Smartlook.setRenderingMode({
            renderingMode: RenderingMode.NATIVE,
          });
      });
  }, [sensitive]);
};

const startSensitive = async () => {
  if (isCapacitor)
    Smartlook.isRecording().then((isRecording: boolean) => {
      if (!isRecording) return;
      Smartlook.setRenderingMode({
        renderingMode: RenderingMode.NO_RENDERING,
      });
    });
};

const stopSensitive = async () => {
  if (isCapacitor)
    Smartlook.isRecording().then((isRecording: boolean) => {
      if (!isRecording) return;
      Smartlook.setRenderingMode({
        renderingMode: RenderingMode.NATIVE,
      });
    });
};

export { useSmartlook, useSmartlookSensitive, startSensitive, stopSensitive };
