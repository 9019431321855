import { useQuery } from "react-query";
import "./Order.scss";
import useAxios from "../hooks/useAxios";
import { RouteComponentProps } from "react-router";
import { Order } from "../models/orders";
import {
  IonButton,
  IonContent,
  IonPage,
  IonSpinner,
  useIonViewWillEnter,
} from "@ionic/react";
import { showTabBar } from "../helpers/tabBar";
import { api_url } from "../config";
import QRCode from "react-qr-code";

interface OrderDetailProps
  extends RouteComponentProps<{ id: string; code: string }> {}

const OrderDetail: React.FC<OrderDetailProps> = ({ match }) => {
  const { axios } = useAxios();
  const orderId = Number(match.params.id);

  useIonViewWillEnter(() => {
    showTabBar();
  });

  const { data: order } = useQuery(
    ["order", orderId, match.params.code],
    () =>
      axios.get<Order>(`/orders/${orderId}`, {
        params: {
          code: match.params.code,
        },
      }),
    {
      select: (res) => res.data,
      refetchInterval: (data) => (data?.state === "COMPLETED" ? false : 1000),
    }
  );

  return (
    <IonPage>
      <IonContent>
        <div className="container">
          {order?.state === "NEW" && (
            <IonSpinner color="primary" name="crescent" />
          )}
          {/* <div>Order {order?.orderId}</div> */}
          <h1>Your order</h1>
          {order?.state === "COMPLETED" && (
            <>
              <p>You will receive tickets on your e-mail soon</p>
              <div>State: {order?.state}</div>

              {/* {order?.items.map((i) => {
            return (
              <div key={i.orderItemId}>
                {i.name} - {i.description}, {i.quantity}
              </div>
            );
          })} */}
              <h2>Your tickets</h2>
              {order?.items
                .flatMap((i) => i.tickets)
                .map((t, i) => {
                  return (
                    <div key={`${i}-${t.code}`} className="ticket">
                      <h3>Ticket {i + 1}</h3>
                      <QRCode
                        size={192}
                        value={t.code}
                        viewBox={`0 0 192 192`}
                      />
                      <div>
                        <a
                          key={t.ticketId}
                          href={`${api_url}/files/tickets/${t.file}`}
                          target="_blank"
                          rel="noreferrer"
                          download
                        >
                          Download
                        </a>
                      </div>
                    </div>
                  );
                  // return <div key={t.ticketId}>{t.file}</div>;
                })}
              <div className="center button-back">
                <IonButton href={`#/events/${order.items[0].eventId}/about`}>
                  Back to event
                </IonButton>
              </div>
            </>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default OrderDetail;
